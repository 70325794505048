import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'lil-gui'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import galaxyVertexShader from './shaders/galaxy/vertex.glsl'
import galaxyFragmentShader from './shaders/galaxy/fragment.glsl'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'



/**
 * Base
 */
// Debug
// const gui = new dat.GUI()


// Canvas
const canvas = document.querySelector('canvas.webgl')

// Renderer
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(window.innerWidth, window.innerHeight)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

// Scene
const scene = new THREE.Scene()
const gltfLoader = new GLTFLoader()
const cakes = [];
const hats = [];
const samosas = [];
const momos = [];
const laptops = [];

const textureLoader = new THREE.TextureLoader()
const matcapTexture = textureLoader.load('textures/matcaps/8.png')
const fontLoader = new FontLoader()

fontLoader.load(
    '/fonts/helvetiker_regular.typeface.json',
    (font) =>
    {
        // Material
        const material = new THREE.MeshMatcapMaterial({ matcap: matcapTexture })

        // Text
        const textGeometry = new TextGeometry(
            "Congratulations on your graduation Srishtreee !\nAs you venture forth into this new chapter,\nnavigating the cosmic expanse of the galaxy,\nremember the battles you've fought, both visible and unseen.\nYour courage in facing not only the challenges\nbefore you but also the silent struggles within\nyour mind is akin to a Jedi mastering the Force.\nYour journey has been a testament to your resilience\nand determination, qualities that\nshine brighter than the stars themselves.\nMay the Force be with you as you chart your\ncourse through the galaxy,\nembracing the unknown with unwavering resolve.\nHere's to a future filled with\nadventures beyond imagination and triumphs yet to be written.",
            {
                font: font,
                size: 0.30,
                height: 0.1,
                curveSegments: 1,
                bevelEnabled: true,
                bevelThickness: 0.01,
                bevelSize: 0.01,
                bevelOffset: 0,
                bevelSegments: 2
            }
        )
        textGeometry.center()

        const text = new THREE.Mesh(textGeometry, material)
        text.position.set(-20, 2, 3)
        text.rotateOnAxis(new THREE.Vector3(0, 1, 0), Math.PI / 2)
        scene.add(text)
        
        const animateText = () => {
            const time = performance.now() * 0.001;
            const offsetY = Math.sin(time) * 0.2;
        
            // Render
            text.position.y = 2 + offsetY;
            
            renderer.render(scene, camera); 
            // Call animateText again on the next frame
            requestAnimationFrame(animateText);
        };

        animateText();
        
        

       
    }
    
)



const ambientLight = new THREE.AmbientLight(0xffffff, 1)
scene.add(ambientLight)

// Initialize camera and controls
const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 100)
camera.position.set(3, 3, 3)
scene.add(camera)

const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

// Function to add cake to scene
function addCakeToScene(gltf) {
    gltf.scene.scale.set(.025, .025, .025)
    const cake = gltf.scene.clone()
    cake.name = 'cake';
    scene.add(cake)
    // Random position
    cake.position.x = (Math.random() - 0.5) * 10
    cake.position.y = (Math.random() - 0.5) * 10
    cake.position.z = (Math.random() - 0.5) * 10
    cake.rotation.x = Math.random() * Math.PI
    cake.rotation.y = Math.random() * Math.PI
    const scale = 0.7
    cake.scale.set(scale, scale, scale)
    cakes.push(cake);
    return cake
    
}

function addLaptopToScene(gltf) {
    gltf.scene.scale.set(.025, .025, .025)
    const laptop = gltf.scene.clone()
    laptop.name = 'laptop';
    scene.add(laptop)
    // Random position
    laptop.position.x = (Math.random() - 0.5) * 10
    laptop.position.y = (Math.random() - 0.5) * 10
    laptop.position.z = (Math.random() - 0.5) * 10
    laptop.rotation.x = Math.random() * Math.PI
    laptop.rotation.y = Math.random() * Math.PI
    const scale = Math.random() * 8
    laptop.scale.set(scale, scale, scale)
    laptops.push(laptop);
    return laptop
}

function addHatToScene(gltf) {
    gltf.scene.scale.set(.025, .025, .025)
    const hat = gltf.scene.clone()
    hat.name = 'hat';
    scene.add(hat)
    // Random position
    hat.position.x = (Math.random() - 0.5) * 10
    hat.position.y = (Math.random() - 0.5) * 10
    hat.position.z = (Math.random() - 0.5) * 10
    hat.rotation.x = Math.random() * Math.PI
    hat.rotation.y = Math.random() * Math.PI
    const scale = Math.random() + 0.2
    hat.scale.set(scale, scale, scale)
    hats.push(hat);
    return hat
}

function addSamosaToScene(gltf) {
    gltf.scene.scale.set(.025, .025, .025)
    const samosa = gltf.scene.clone()
    samosa.name = 'samosa';
    scene.add(samosa)
    // Random position
    samosa.position.x = (Math.random() - 0.5) * 10
    samosa.position.y = (Math.random() - 0.5) * 10
    samosa.position.z = (Math.random() - 0.5) * 10
    samosa.rotation.x = Math.random() * Math.PI
    samosa.rotation.y = Math.random() * Math.PI
    const scale = Math.random() * 7
    samosa.scale.set(scale, scale, scale)
    samosas.push(samosa);
    return samosa
}

function addMomoToScene(gltf) {
    gltf.scene.scale.set(.025, .025, .025)
    const momo = gltf.scene.clone()
    momo.name = 'momo';
    scene.add(momo)
    // Random position
    momo.position.x = (Math.random() - 0.5) * 10 
    momo.position.y = (Math.random() - 0.5) * 10
    momo.position.z = (Math.random() - 0.5) * 10
    momo.rotation.x = Math.random() * Math.PI 
    momo.rotation.y = Math.random() * Math.PI
    const scale = Math.random() + 0.1
    momo.scale.set(scale, scale, scale)
    momos.push(momo);
    return momo
}


// Load cake model
gltfLoader.load(
    '/models/cake/cake.gltf',
    (gltf) => {
        console.log(gltf)
        // Create 10 instances of the cake
        for (let i = 0; i < 4; i++) {
            const cake = addCakeToScene(gltf);
            if (cake) {
                // Store the cake object for rotation updates
                cakes.push(cake);
            }
        }
    }
)

gltfLoader.load(
    '/models/laptop/scene.gltf',
    (gltf) => {
        console.log(gltf)
        // Create 10 instances of the cake
        for (let i = 0; i < 4; i++) {
            const laptop = addLaptopToScene(gltf);
            if (laptop) {
                // Store the laptop object for rotation updates
                laptops.push(laptop);
            }
        }
    }
)

gltfLoader.load(
    '/models/samosa/scene.gltf',
    (gltf) => {
        console.log(gltf)
        // Create 10 instances of the cake
        for (let i = 0; i < 4; i++) {
            const samosa = addSamosaToScene(gltf);
            if (samosa) {
                // Store the cake object for rotation updates
                samosas.push(samosa);
            }
        }
    }
)

gltfLoader.load(
    '/models/hat/hat.gltf',
    (gltf) => {
        console.log(gltf)
        // Create 10 instances of the cake
        for (let i = 0; i < 4; i++) {
            const hat = addHatToScene(gltf);
            if (hat) {
                // Store the cake object for rotation updates
                hats.push(hat);
            }
        }
    }
)

gltfLoader.load(
    '/models/momo/scene.gltf',
    (gltf) => {
        console.log(gltf)
        // Create 10 instances of the cake
        for (let i = 0; i < 4; i++) {
            const momo = addMomoToScene(gltf);
            if (momo) {
                // Store the cake object for rotation updates
                momos.push(momo);
            }
        }
    }
)





/**
 * Galaxy
 */
const parameters = {
    count: 300000,
    size: 0.006,
    radius: 8,
    branches: 6,
    spin: 1,
    randomness: 0.6,
    randomnessPower: 4,
    insideColor: '#ff6030',
    outsideColor: '#0549f5'
}

let geometry = null
let material = null
let points = null

// Function to generate galaxy
const generateGalaxy = () => {
    if (points !== null) {
        geometry.dispose()
        material.dispose()
        scene.remove(points)
    }

    /**
     * Geometry
     */
    geometry = new THREE.BufferGeometry()
    const positions = new Float32Array(parameters.count * 3)
    const randomness = new Float32Array(parameters.count * 3)
    const colors = new Float32Array(parameters.count * 3)
    const scales = new Float32Array(parameters.count * 1)
    const insideColor = new THREE.Color(parameters.insideColor)
    const outsideColor = new THREE.Color(parameters.outsideColor)

    for (let i = 0; i < parameters.count; i++) {
        const i3 = i * 3

        // Position
        const radius = Math.random() * parameters.radius
        const branchAngle = (i % parameters.branches) / parameters.branches * Math.PI * 2
        const randomX = Math.pow(Math.random(), parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : -1) * parameters.randomness * radius
        const randomY = Math.pow(Math.random(), parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : -1) * parameters.randomness * radius
        const randomZ = Math.pow(Math.random(), parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : -1) * parameters.randomness * radius

        positions[i3] = Math.cos(branchAngle) * radius
        positions[i3 + 1] = 0
        positions[i3 + 2] = Math.sin(branchAngle) * radius
        randomness[i3] = randomX
        randomness[i3 + 1] = randomY
        randomness[i3 + 2] = randomZ

        // Color
        const mixedColor = insideColor.clone()
        mixedColor.lerp(outsideColor, radius / parameters.radius)
        colors[i3] = mixedColor.r
        colors[i3 + 1] = mixedColor.g
        colors[i3 + 2] = mixedColor.b

        // Scale
        scales[i] = Math.random()
    }

    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
    geometry.setAttribute('aRandomness', new THREE.BufferAttribute(randomness, 3))
    geometry.setAttribute('color', new THREE.BufferAttribute(colors, 3))
    geometry.setAttribute('aScale', new THREE.BufferAttribute(scales, 1))

    /**
     * Material
     */
    material = new THREE.ShaderMaterial({
        depthWrite: false,
        blending: THREE.AdditiveBlending,
        vertexColors: true,
        uniforms: {
            uTime: { value: 0 },
            uSize: { value: 30 * renderer.getPixelRatio() }
        },
        vertexShader: galaxyVertexShader,
        fragmentShader: galaxyFragmentShader
    })

    /**
     * Points
     */
    points = new THREE.Points(geometry, material)
    scene.add(points)
}



// Generate the first galaxy
generateGalaxy()

// // GUI controls
// gui.add(parameters, 'count').min(100).max(1000000).step(100).onFinishChange(generateGalaxy)
// gui.add(parameters, 'radius').min(0.01).max(20).step(0.01).onFinishChange(generateGalaxy)
// gui.add(parameters, 'branches').min(2).max(20).step(1).onFinishChange(generateGalaxy)
// gui.add(parameters, 'randomness').min(0).max(2).step(0.001).onFinishChange(generateGalaxy)
// gui.add(parameters, 'randomnessPower').min(1).max(10).step(0.001).onFinishChange(generateGalaxy)
// gui.addColor(parameters, 'insideColor').onFinishChange(generateGalaxy)
// gui.addColor(parameters, 'outsideColor').onFinishChange(generateGalaxy)

// Resize listener
window.addEventListener('resize', () => {
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

// Animation loop

const clock = new THREE.Clock()
const tick = () => {
    const elapsedTime = clock.getElapsedTime()

    // Update material
    material.uniforms.uTime.value = elapsedTime

    const galaxyPosition = points.position // Center of the galaxy
    const galaxyRadius = parameters.radius

    cakes.forEach((cake, index) => {
        const t = elapsedTime * (0.5 + index * 0.1); // Adjust speed for each cake

        // Calculate orbit position based on galaxy geometry
        const orbitIndex = Math.floor((t % parameters.count) / parameters.count * cakes.length)
        const orbitPosition = new THREE.Vector3().fromBufferAttribute(points.geometry.attributes.position, orbitIndex)

        // Calculate position relative to the center of the galaxy
        const relativePosition = orbitPosition.clone().sub(galaxyPosition)

        // Rotate the position around the center
        const angle = (2 * Math.PI - t * parameters.spin)
        relativePosition.applyAxisAngle(new THREE.Vector3(0, 1, 0), angle)

        // Calculate final position
        const finalPosition = relativePosition.add(galaxyPosition)

        // Update cake position and rotation
        cake.position.copy(finalPosition)
        cake.rotation.y += 0.01
        
        cake.position.y = (index % 2 === 0 ? 1 : -1) * Math.floor(index / 2) * 2 + 2;
        
    })

    hats.forEach((hat, index) => {
        const t = elapsedTime * (0.6 + index * 0.1); // Adjust speed for each cake

        // Calculate orbit position based on galaxy geometry
        const orbitIndex = Math.floor((t % parameters.count) / parameters.count * hats.length)
        const orbitPosition = new THREE.Vector3().fromBufferAttribute(points.geometry.attributes.position, orbitIndex)

        // Calculate position relative to the center of the galaxy
        const relativePosition = orbitPosition.clone().sub(galaxyPosition)

        // Rotate the position around the center
        const angle = (2 * Math.PI - t * parameters.spin)
        relativePosition.applyAxisAngle(new THREE.Vector3(0, 1, 0), angle)

        // Calculate final position
        const finalPosition = relativePosition.add(galaxyPosition)

        // Update cake position and rotation
        hat.position.copy(finalPosition)
        hat.rotation.y += 0.01
        // hat.position.y += 1.5
        hat.position.y = (index % 2 === 0 ? 1 : -1) * Math.floor(index / 2) * 2 + 1;
        
        
    })

    laptops.forEach((laptop, index) => {
        const t = elapsedTime * (0.4 + index * 0.1); // Adjust speed for each cake

        // Calculate orbit position based on galaxy geometry
        const orbitIndex = Math.floor((t % parameters.count) / parameters.count * laptops.length)
        const orbitPosition = new THREE.Vector3().fromBufferAttribute(points.geometry.attributes.position, orbitIndex)

        // Calculate position relative to the center of the galaxy
        const relativePosition = orbitPosition.clone().sub(galaxyPosition)

        // Rotate the position around the center
        const angle = (2 * Math.PI - t * parameters.spin)
        relativePosition.applyAxisAngle(new THREE.Vector3(0, 1, 0), angle)

        // Calculate final position
        const finalPosition = relativePosition.add(galaxyPosition)

        // Update cake position and rotation
        laptop.position.copy(finalPosition)
        laptop.rotation.y += 0.01
        laptop.position.y = (index % 2 === 0 ? 1 : -1) * (Math.floor(index / 2) * 2 + 4);
        
        
    })

    momos.forEach((momo, index) => {
        const t = elapsedTime * (0.3 + index * 0.1); // Adjust speed for each momo

        // Calculate orbit position based on galaxy geometry
        const orbitIndex = Math.floor((t % parameters.count) / parameters.count * momos.length)
        const orbitPosition = new THREE.Vector3().fromBufferAttribute(points.geometry.attributes.position, orbitIndex)

        // Calculate position relative to the center of the galaxy
        const relativePosition = orbitPosition.clone().sub(galaxyPosition)

        // Rotate the position around the center
        const angle = (2 * Math.PI - t * parameters.spin)
        relativePosition.applyAxisAngle(new THREE.Vector3(0, 1, 0), angle)

        // Calculate final position
        const finalPosition = relativePosition.add(galaxyPosition)

        // Update momo position and rotation
        momo.position.copy(finalPosition)
        momo.rotation.y += 0.01
        // momo.position.y += 2
        momo.position.y = (index % 2 === 0 ? 1 : -1) * Math.floor(index / 2) * 2 + 3; // Offset to avoid overlap
    })

    samosas.forEach((samosa, index) => {
        const t = elapsedTime * (0.7 + index * 0.1); // Adjust speed for each cake

        // Calculate orbit position based on galaxy geometry
        const orbitIndex = Math.floor((t % parameters.count) / parameters.count * samosas.length)
        const orbitPosition = new THREE.Vector3().fromBufferAttribute(points.geometry.attributes.position, orbitIndex)

        // Calculate position relative to the center of the galaxy
        const relativePosition = orbitPosition.clone().sub(galaxyPosition)

        // Rotate the position around the center
        const angle = (2 * Math.PI - t * parameters.spin)
        relativePosition.applyAxisAngle(new THREE.Vector3(0, 1, 0), angle)

        // Calculate final position
        const finalPosition = relativePosition.add(galaxyPosition)

        // Update cake position and rotation
        samosa.position.copy(finalPosition)
        samosa.rotation.y += 0.01
        // samosa.position.y -= 2
        // samosa.position.x += 2
        samosa.position.y = (index % 2 === 0 ? 1 : -1) * Math.floor(index / 2) * 2;
        
    })

    

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()
